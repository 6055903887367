<template>
    <div>
        <div class="px-2 productDisplay">
            <div>
                <img :src="currentImage.URL" class="mainImage">
            </div>

            <div v-if="prodImages && prodImages.length > 0" class="allCarouselImages">
                <v-slide-group next-icon="" prev-icon="">
                    <v-slide-item v-for="imageURL, index in prodImages" :key="index">
                        <div>
                            <img :src="imageURL.URL" class="carouselImage clickable-pointer" v-on:click="changeImage(imageURL)">
                        </div>
                    </v-slide-item>
                </v-slide-group>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "productDisplayComponent",
    components: {

    },
    data() {
        return {
            currentImage: '',
        }
    },
    props: {
        prodImages: {
            type: Array,
            required: true
        }
    },
    mounted() {
        this.currentImage = this.prodImages[0];
    },
    methods: {
        changeImage(imgURL) {
            this.currentImage = imgURL;
        }
    }
}
</script>

<style scoped>
.mainImage {
    width : 300px;
    height: 300px;
    border-radius: 2%;
}
.carouselImage {
    width: 70px;
    height: 70px;
    border-radius: 5%;
    margin-right: 10px;
}
.allCarouselImages {
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.productDisplay {
    width : 320px;
    height: 420px;
}
</style>